<template>
  <v-select
    :options="options"
    @open="fetchOptions"
    @search="fetchOptions"
  />
</template>

<script>
import { ref } from 'vue'
import debounce from '@/utils/debounce'
import { axios } from '@/axios'

export default {
  setup () {
    const options = ref([])

    const fetchApi = (params = {}) => {
      return axios.get('/admin/customers', { params }).then(({ data }) => data)
    }

    const fetchOptions = debounce((search = '') => {
      fetchApi({ name: search }).then((data) => {
        options.value = data.data.map(({ id, name }) => ({
          label: name,
          value: id
        }))
      })
    }, 500)

    return {
      options,
      fetchOptions
    }
  }
}
</script>
