<template>
  <Layout :pagetitle="pageTitle">
    <div class="row">
      <div class="col-lg-12">
        <!-- filters / start -->
        <div class="card mt-3">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-lg-4 mb-2">
                <select-contest
                  v-model="filter.contest_id"
                  placeholder="Pesquisar por sorteio"
                />
              </div>
              <div class="col-sm-12 col-lg-4 mb-2">
                <select-customer
                  v-model="filter.customer_id"
                  placeholder="Pesquisar por cliente"
                />
              </div>
              <div class="col-sm-12 col-lg-4 mb-2">
                <select
                  v-model="filter.status"
                  class="form-select"
                >
                  <option :value="null">
                    Status
                  </option>
                  <option
                    v-for="(status, key) in statusMap"
                    :key="key"
                    :value="key"
                  >
                    {{ status.label }}
                  </option>
                </select>
              </div>
              <div class="col-sm-12 col-lg-3 mb-2">
                <input
                  v-model="filter.reserved_at.from"
                  v-maska="'##/##/####'"
                  type="text"
                  class="form-control"
                  placeholder="Data reserva (inicial)"
                >
              </div>
              <div class="col-sm-12 col-lg-3 mb-2">
                <input
                  v-model="filter.reserved_at.to"
                  v-maska="'##/##/####'"
                  type="text"
                  class="form-control"
                  placeholder="Data reserva (final)"
                >
              </div>
              <div class="col-sm-12 col-lg-3 mb-2">
                <input
                  v-model="filter.payment_at.from"
                  v-maska="'##/##/####'"
                  type="text"
                  class="form-control"
                  placeholder="Data pagamento (inicial)"
                >
              </div>
              <div class="col-sm-12 col-lg-3 mb-2">
                <input
                  v-model="filter.payment_at.to"
                  v-maska="'##/##/####'"
                  type="text"
                  class="form-control"
                  placeholder="Data pagamento (final)"
                >
              </div>
            </div>
          </div>
        </div>
        <!-- filters / end -->
        <div class="card mt-3">
          <div class="card-body">
            <app-loader-bar :loading="loading" />
            <div class="table-responsive">
              <table class="table table-striped mb-4">
                <thead>
                  <tr>
                    <th scope="col">
                      Sorteio
                    </th>
                    <th scope="col">
                      Número
                    </th>
                    <th scope="col">
                      Cliente
                    </th>
                    <th scope="col">
                      Pedido
                    </th>
                    <th scope="col">
                      Status
                    </th>
                    <th scope="col">
                      Reservado em
                    </th>
                    <th scope="col">
                      Pago em
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="number in numbers"
                    :key="number.id"
                  >
                    <td>
                      <router-link :to="{ name: 'contest_show', params: { id: number.contest_id }}">
                        {{ number.contest_title }}
                      </router-link>
                    </td>
                    <td>{{ number.number }}</td>
                    <td>
                      <router-link
                        v-if="number.customer_id"
                        :to="{ name: 'customer_show', params: { id: number.customer_id }}"
                      >
                        {{ number.customer_name }}
                      </router-link>
                    </td>
                    <td>
                      <router-link
                        v-if="number.order_id"
                        :to="{ name: 'order_show', params: { id: number.order_id }}"
                      >
                        {{ number.order_id }}
                      </router-link>
                    </td>
                    <td>
                      <span
                        class="badge rounded-pill"
                        :class="statusGet(number.status, 'class')"
                      >
                        {{ statusGet(number.status, 'label') }}
                      </span>
                    </td>
                    <td>{{ number.reserved_at ? $d(number.reserved_at, 'info') : '' }}</td>
                    <td>{{ number.payment_at ? $d(number.payment_at, 'info') : '' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <app-pagination
                v-model="page"
                class="pagination"
                :pages="pages"
                :range-size="1"
                @update:modelValue="fetchNumbers"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import { computed, onMounted, ref, watch } from 'vue'
import { getNumbers } from './api'
import AppPagination from '@/components/app-pagination'
import SelectCustomer from '@/components/select-customer'
import SelectContest from '@/components/select-contest'
import debounce from '@/utils/debounce'

const statusMap = {
  FREE: {
    label: 'Disponível',
    class: 'badge-soft-primary'
  },
  RESERVED: {
    label: 'Reservado',
    class: 'badge-soft-danger'
  },
  PAID: {
    label: 'Pago',
    class: 'badge-soft-success'
  }
}

export default {
  setup () {
    const loading = ref(true)
    const page = ref(1)
    const pages = ref(0)
    const total = ref(0)
    const numbers = ref([])
    const filter = ref({
      customer_id: null,
      contest_id: null,
      status: null,
      reserved_at: {
        from: null,
        to: null
      },
      payment_at: {
        from: null,
        to: null
      }
    })

    const dateToISO = (date) => {
      if (date && date.length === 10) {
        return date.split('/').reverse().join('-')
      }
      return null
    }

    const fetchNumbers = debounce(() => {
      const params = {
        page: page.value,
        customer_id: filter.value?.customer_id?.value,
        contest_id: filter.value?.contest_id?.value,
        status: filter.value.status,
        reserved_at: {
          gte: dateToISO(filter.value.reserved_at.from),
          lte: dateToISO(filter.value.reserved_at.to)
        },
        payment_at: {
          gte: dateToISO(filter.value.payment_at.from),
          lte: dateToISO(filter.value.payment_at.to)
        }
      }

      loading.value = true

      getNumbers(params).then((data) => {
        numbers.value = data.data
        pages.value = data.last_page
        total.value = data.total
      }).finally(() => {
        loading.value = false
      })
    }, 1000)

    const statusGet = (status, key) => statusMap[status][key]

    onMounted(() => {
      fetchNumbers()
    })

    watch(filter, () => {
      page.value = 1
      fetchNumbers()
    }, { deep: true })

    const pageTitle = computed(() => `Números (${total.value})`)

    return {
      numbers,
      page,
      pages,
      filter,
      fetchNumbers,
      statusGet,
      pageTitle,
      statusMap,
      loading
    }
  },
  components: {
    Layout,
    AppPagination,
    SelectCustomer,
    SelectContest
  }
}

</script>
